$(document).ready(function () {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s = document.createElement('script');
    s.async = 1;
    s.src = 'https://embed.tawk.to/59d349974854b82732ff33e4/default';
    s.charset = 'UTF-8';
    s.setAttribute('crossorigin', '*');
    document.body.appendChild(s);
  })();
});
