$(document).ready(function () {
  (function (h, o, t, j, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 1462355, hjsv: 6 };
    r = o.createElement('script');
    r.async = 1;
    r.defer = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    o.body.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
});
